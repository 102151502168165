<template>
  <div class="rules-add">
    <div class="breadcrumb-conatiner">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/rules/list' }">规则引擎</el-breadcrumb-item>
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="add-conatiner">
      <div class="form-conatiner">
        <!-- 规则信息 -->
        <div>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/rules/icon1.png" alt="">
            </div>
            <div class="item-name">规则信息</div>
          </div>
          <el-form :model="form" label-position="left" :rules="rules" ref="formCustom1">
            <div class="form-top">
              <div>
                <el-form-item label="规则描述：" prop="desc" class="label-feather">
                  <el-input v-model="form.desc" class="width340" placeholder="请输入规则描述"
                    :disabled="ruleType === 'view'" />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="所属产品：" prop="pks" class="label-feather">
                  <el-select v-model="pks" placeholder="请选择产品" class="width340" multiple @change="handlePkChange"
                    :max-collapse-tags="3" :disabled="ruleType === 'view'" collapse-tags collapse-tags-tooltip>
                    <el-option v-for="item in pkList" :key="item.pk" :label="item.name" :value="item.pk" />
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <el-form-item label="规则内容：" prop="filterParams" class="label-feather">
              <div class="rule-box">
                <div class="form-top">
                  <span class="select-btn">SELECT</span>
                  <el-input v-model="filterParamsShow" class="width598" placeholder="请输入"
                    :disabled="ruleType === 'view'" />
                </div>
                <div class="form-top1">
                  <span class="select-btn">WHERE</span>
                  <el-input type="textarea" v-model="form.condition" class="width598" placeholder="请输入过滤条件"
                    :disabled="ruleType === 'view'" />
                </div>
              </div>
            </el-form-item>

          </el-form>
        </div>
        <!-- 附加条件 -->
        <div>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/rules/icon2.png" alt="">
            </div>
            <div class="item-name">附加条件</div>
          </div>
          <div class="tooltip-title">触发间隔：2次触发规则之间间隔达到之后才会真正推送；连续次数：条件连续次数到达后才会触发真正推送</div>
          <el-form :model="form" label-position="left" :rules="rules" ref="formCustom2">
            <el-form-item label="间隔时间(秒)" prop="triggerTimeSecs" class="label-feather">
              <el-input-number v-model="form.triggerTimeSecs" :min="0" class="wdith280"
                :disabled="ruleType === 'view'" />
            </el-form-item>
            <el-form-item label="连续次数" prop="triggerCount" class="label-feather">
              <el-input-number v-model="form.triggerCount" :min="0" class="wdith280" :disabled="ruleType === 'view'" />
            </el-form-item>
          </el-form>
        </div>
        <!-- 数据转发 -->
        <div>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/rules/icon3.png" alt="">
            </div>
            <div class="item-name">数据转发</div>
          </div>
          <div class="tooltip-title">
            若是发送到topic，则无需输入“目标地址”。点击保存后，系统将自动生成Topic和接入鉴权信息。<br>
            kafka客户端版本为：org.apache.kafka:kafka-clients:3.0.0(推荐2.x及其以上版本kafka)
          </div>
          <el-form :model="form" label-position="left" :rules="rules" ref="formCustom3">
            <el-form-item label="选择操作" prop="destType" class="label-feather">
              <el-select v-model="form.destType" placeholder="请选择操作" class="wdith800" @change="handleDestTypeChange"
                :disabled="ruleType === 'view' || ruleType === 'edit'">
                <el-option v-for="item in taskList" :key="item.name" :label="item.desc" :value="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item label="目标地址"
              v-if="(ruleType == 'edit' || ruleType == 'view') && selectDest && !selectDest.allowChangeDest && form.dest"
              class="label-feather">
              <el-input v-model="form.dest" class="wdith800" placeholder="请输入" disabled />
              <el-button type="primary" text style="margin-left: 20px;"
                @click="copyToClipboard(form.dest)">复制</el-button>
            </el-form-item>
            <el-form-item label="目标地址" v-if="selectDest && selectDest.allowChangeDest" prop="dest"
              class="label-feather">
              <el-input v-model="form.dest" class="wdith800" placeholder="请输入" :disabled="ruleType === 'view'" />
            </el-form-item>
            <el-form-item label="Kafka Topic" v-if="selectDest && selectDest.name === 'KAFKA'" prop="kafkaTopic"
              class="label-feather">
              <el-input v-model="form.kafkaTopic" class="wdith800" placeholder="请输入" :disabled="ruleType === 'view'" />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <div class="btn-conatiner">
        <div class="cancel-btn" @click="handleBack" v-domPreventReClick>返回</div>
        <div class="add-btn" @click="create" v-if="ruleType !== 'view'" v-domPreventReClick>{{ isEdit ? '确认' : '创建' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import api from "@/api/api"
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import { copyToClipboard } from "@/hooks/common";
const route = useRoute()
const router = useRouter()
const pkList = ref([])
const taskList = ref([]);
const filterParamsShow = ref("*");
const form = reactive({
  desc: "",
  pks: [],
  destType: "",
  filterParams: [],
  condition: '',
  triggerTimeSecs: 0,
  triggerCount: 0,
  kafkaTopic: "",
})
const pks = ref([]);
const rules = {
  desc: [
    { required: true, message: "规则描述不能为空", trigger: "change" },
  ],
  pks: [
    { required: true, message: "所属产品不能为空", trigger: "change" },
  ],
  destType: [
    { required: true, message: "操作不能为空", trigger: "change" },
  ],
  dest: [
    { required: true, message: "目标地址不能为空", trigger: "change" },
  ],
}
const isEdit = ref(false);
const ruleType = ref("add");
const rId = ref(null);
const title = ref("创建规则")
onMounted(() => {
  api.getRuleTaskType().then(res => {
    if (res.code === "0") {
      taskList.value = res.res.data;
    }
  })
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      const all = {
        pk: "all",
        name: "全部",
      }
      pkList.value = [all, ...res.res.data];
    }
  })
  const { type, ruleId } = route.query;
  if (ruleId && type === "edit") {
    // 编辑
    ruleType.value = "edit";
    rId.value = ruleId;
    isEdit.value = true;
    title.value = "编辑规则"
    getRuleInfo()
  } else if (ruleId && type === "view") {
    // 查看
    ruleType.value = "view";
    rId.value = ruleId;
    title.value = "查看规则"
    getRuleInfo()
  } else if (type === "add") {
    // 新增
    ruleType.value = "add";
  }
})

const getRuleInfo = () => {
  api.getRuleInfoByRuleId(rId.value).then(res => {
    if (res.code === "0") {
      const { desc, dest, destType, kafkaTopic, triggerCount, triggerTimeSecs, condition, filterParams } = res.res.data;
      form.desc = desc;
      form.dest = dest;
      form.destType = destType;
      form.triggerTimeSecs = triggerTimeSecs;
      form.triggerCount = triggerCount;
      form.kafkaTopic = kafkaTopic;
      form.condition = condition;
      taskList.value.forEach(item => {
        if (item.name === destType) {
          selectDest.value = item;
        }
      })
      console.log(selectDest.value);
      if (filterParams.length > 0) {
        form.filterParams = filterParams;
        const list = []
        filterParams.forEach(item => {
          list.push(item.name)
        })
        filterParamsShow.value = list.join(";")
      }
      const infoPks = res.res.data.pks
      if (infoPks.length === pkList.value.length - 1) {
        // 全部;
        pks.value = ["all", ...infoPks];
        form.pks = ["all", ...infoPks];
      } else {
        pks.value = infoPks;
        form.pks = infoPks;
      }

    }
  })
}
const formCustom1 = ref(null); // 规则信息
const formCustom2 = ref(null); // 附加条件
const formCustom3 = ref(null); // 数据转发
const create = () => {
  if (filterParamsShow.value) {
    const params = filterParamsShow.value.split(";");
    const list = []
    params.forEach(item => {
      const obj = {
        name: item,
        alias: null
      }
      list.push(obj);
    })
    form.filterParams = list;
  }
  formCustom1.value.validate((valid) => {
    if (valid) {
      check1()
    } else {
      return false;
    }
  });
}

const check1 = () => {
  formCustom2.value.validate((valid) => {
    if (valid) {
      check2()
    } else {
      return false;
    }
  });
}

const check2 = () => {
  formCustom3.value.validate((valid) => {
    if (valid) {
      const newPks = form.pks
      let hasAll = false;
      form.pks.forEach(pk => {
        if (pk === "all") {
          hasAll = true;
          return;
        }
      })
      if (hasAll) {
        newPks.splice(0, 1)
      }
      form.pks = newPks;
      if (selectDest.value) {
        const item = selectDest.value;
        if (item.verifyPatten && form.dest) {
          // 校验
          const reg = new RegExp(item.verifyPatten, "g");
          const result = reg.test(form.dest)
          if (!result) {
            ElMessage.error("目标地址校验不通过" + item.verifyPatten)
            return false;
          }
        }
      }
      if (ruleType.value === "add") {
        add()
      } else {
        edit();
      }
    } else {
      return false;
    }
  });
}

const add = () => {
  console.log("add");
  api.createRules(form).then(res => {
    if (res.code === "0") {
      ElMessage.success("创建成功！")
      router.push("/rules/list")
    }
  })
}
const edit = () => {
  api.editRules(rId.value, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("更新成功！")
      router.push("/rules/list")
    }
  })
}

const selectDest = ref(null);
const handleDestTypeChange = (val) => {
  form.kafkaTopic = "";
  taskList.value.forEach(item => {
    if (item.name === val) {
      console.log(item);
      selectDest.value = item;
    }
  })
}

const handlePkChange = (v) => {
  const older = form.pks;
  let isAll = false;
  v.forEach(pk => {
    if (pk === "all") {
      isAll = true;
      return;
    }
  })
  if (isAll) {
    const list = [];
    pkList.value.forEach(pk => {
      list.push(pk.pk)
    })
    pks.value = list;
    form.pks = list;
  } else {
    let oldAll = false;
    older.forEach(pk => {
      if (pk === "all") {
        oldAll = true;
        return;
      }
    })
    if (oldAll) {
      pks.value = []
      form.pks = []
    } else {
      form.pks = v
    }

  }

}
const handleBack = () => {
  router.push("/rules/list")
}
</script>

<style lang="less" scoped>
.btn-conatiner {
  float: right;
  margin-top: 14px;
  margin-right: 50px;
  display: flex;
  align-items: center;

  .add-btn {
    margin-left: 20px;
    border-radius: 4px;
    width: 136px;
    cursor: pointer;
    text-align: center;
    background: #367CC5;
    color: #ffffff;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
  }

  .cancel-btn {
    box-sizing: border-box;
    border-radius: 4px;
    width: 136px;
    cursor: pointer;
    text-align: center;
    color: #333333;
    border: 1px solid #B7BBC0;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
  }
}

.footer-container {
  border-top: 1px solid #E9ECF0;
  margin-top: 20px;
}

.wdith800 {
  width: 770px;
}

.wdith280 {
  width: 280px;
}

.width598 {
  width: 598px;
}

.rule-box {
  box-sizing: border-box;
  border: 1px dashed #E9ECF0;
  background: #F9FCFD;
  padding: 13px 20px;
  border-radius: 8px;
}

.width340 {
  width: 315px;
}

.breadcrumb-conatiner {
  box-sizing: border-box;
  padding: 20px 28px;
  border-bottom: 1px solid #E9ECF0;
}

.rules-add {
  box-sizing: border-box;
}

.add-conatiner {
  // display: flex;

  .tooltip-title {
    font-size: 14px;
    color: #7d7f8c;
    line-height: 40px;
    // margin-left: 20px;
    margin-bottom: 10px;
  }

  .form-conatiner {
    box-sizing: border-box;
    padding: 0 30px 30px 30px;
    background: #ffffff;

    &>div {

      .item-title {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 0;

        .item-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .item-name {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .form-top {
      display: flex;
      align-items: center;
      // margin-bottom: 18px;

      &>div:first-child {
        margin-right: 40px;
      }
    }

    .form-top:first-child {
      margin-bottom: 10px;
    }

    .form-top1 {
      display: flex;
      align-items: flex-start;
      // margin-bottom: 18px;

      &>div:first-child {
        margin-right: 40px;
      }
    }

    .select-btn {
      display: inline-block;
      height: 35px;
      color: #09a3b1;
      background: #E8F8F8;
      border-radius: 5px;
      line-height: 35px;
      width: 111px;
      text-align: center;
      margin-right: 20px;
    }
  }
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 100px;
  }
}
</style>